import firebase from 'firebase/app';
import 'firebase/auth';
// import 'firebase/database';
import 'firebase/firestore';
// import 'firebase/messaging';
// import 'firebase/storage';


const config = {
    apiKey: "AIzaSyBcN4iKMc5yzLbvYZgp9X3FAbzPSIvS8vQ",
    authDomain: "px-videoconference-demo.firebaseapp.com",
    databaseURL: "https://px-videoconference-demo.firebaseio.com",
    projectId: "px-videoconference-demo",
    storageBucket: "px-videoconference-demo.appspot.com",
    messagingSenderId: "365376300526",
    appId: "1:365376300526:web:fc06c75621a1a74f86daff"
};

const qaConfig = {
    apiKey: "AIzaSyCsBtI96TxeNpBxs-plGDLqex8EkOhcmYo",
    authDomain: "face2face-qa.firebaseapp.com",
    databaseURL: "https://face2face-qa.firebaseio.com",
    projectId: "face2face-qa",
    storageBucket: "face2face-qa.appspot.com",
    messagingSenderId: "668182349968",
    appId: "1:668182349968:web:968ca2f6881c8501ceb015"
  };


  const devConfig = {
    apiKey: "AIzaSyA_ijITEfiRUEadRcX0VpmWdGLPL-oLjys",
    authDomain: "face2face-dev.firebaseapp.com",
    databaseURL: "https://face2face-dev.firebaseio.com",
    projectId: "face2face-dev",
    storageBucket: "face2face-dev.appspot.com",
    messagingSenderId: "1085444618862",
    appId: "1:1085444618862:web:aec8f395ef750f0a24047f"
  };
  const coConfig = {
    apiKey: "AIzaSyBGGgKrKPOrlRKLo-ztOd3X0AJ4TuFzVpo",
    authDomain: "face2face-co.firebaseapp.com",
    databaseURL: "https://face2face-co.firebaseio.com",
    projectId: "face2face-co",
    storageBucket: "face2face-co.appspot.com",
    messagingSenderId: "1030334003853",
    appId: "1:1030334003853:web:efde6464a823bcf1b280c4"
  };

  const uxConfig = {
    apiKey: "AIzaSyD4hvy4NjB1khKmbGLRft7nDsL-PVd7neo",
    authDomain: "face2face-ux.firebaseapp.com",
    databaseURL: "https://face2face-ux.firebaseio.com",
    projectId: "face2face-ux",
    storageBucket: "face2face-ux.appspot.com",
    messagingSenderId: "923188892025",
    appId: "1:923188892025:web:e2d6555a9440e99f5c9fa8"
  };

  const docsConfig = {
    apiKey: "AIzaSyBx5NDbGApUbtyhllUvLcuNuqn6eW4JcnA",
    authDomain: "face2face-docs.firebaseapp.com",
    databaseURL: "https://face2face-docs.firebaseio.com",
    projectId: "face2face-docs",
    storageBucket: "face2face-docs.appspot.com",
    messagingSenderId: "256698259995",
    appId: "1:256698259995:web:935963ee17912215e736be"
  };

  const sdrConfig = {
    apiKey: "AIzaSyD32NaxzZ5haMubNLrWskLAby22yOWG87M",
    authDomain: "face2face-sdr.firebaseapp.com",
    databaseURL: "https://face2face-sdr.firebaseio.com",
    projectId: "face2face-sdr",
    storageBucket: "face2face-sdr.appspot.com",
    messagingSenderId: "369286249409",
    appId: "1:369286249409:web:e9aae1eda9030729f8cd40"
  };
  const chanduConfig = {
    apiKey: "AIzaSyDscVVGV28jvqku5bD1VrFE1IsvcLVWG1Y",
    authDomain: "face2face-chandu.firebaseapp.com",
    databaseURL: "https://face2face-chandu.firebaseio.com",
    projectId: "face2face-chandu",
    storageBucket: "face2face-chandu.appspot.com",
    messagingSenderId: "750007422196",
    appId: "1:750007422196:web:1be40e27320479e746e2a1"
  };
  const eduConfig = {
    apiKey: "AIzaSyDGgZDGrKaPxBmRuIQ9PjoNOBfQfIfPk0g",
    authDomain: "face2face-edu.firebaseapp.com",
    databaseURL: "https://face2face-edu.firebaseio.com",
    projectId: "face2face-edu",
    storageBucket: "face2face-edu.appspot.com",
    messagingSenderId: "535976762780",
    appId: "1:535976762780:web:04f791af806898c549fc59"
  };
  const pConfig = {
    apiKey: "AIzaSyBLaE5IEtyRQy_-TyrmfgxHMar-T_CBOBw",
    authDomain: "face2face-p.firebaseapp.com",
    projectId: "face2face-p",
    storageBucket: "face2face-p.appspot.com",
    messagingSenderId: "459404530157",
    appId: "1:459404530157:web:f132c899453a4aeca317e4"
  };

// firebase.initializeApp(config);
// firebase.initializeApp(qaConfig);
// firebase.initializeApp(devConfig);
// firebase.initializeApp(coConfig);
// firebase.initializeApp(uxConfig);
firebase.initializeApp(sdrConfig);
// firebase.initializeApp(docsConfig);
// firebase.initializeApp(chanduConfig);
// firebase.initializeApp(eduConfig);
// firebase.initializeApp(pConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
// export const storage = firebase.storage();

// window.db = db;

export default firebase;